
/* define  customized font for lumina using the poppins font */
@font-face {
    font-family:"lumina-regular";
    src: url("./fonts/Poppins-Regular.ttf");
}

@font-face {
    font-family:"lumina-black";
    src: url("./fonts/Poppins-Black.ttf");
}

/*  define root styles or properties  */
:root{
    --lumina-brand-color:#C1FF3F;    /* brand color for lumina  */
    --lumina-transparent-brand-color-hover: #404c26; /* transparent brand color*/
    --lumina-transparent-brand-color: #3A4521; /* transparent brand color*/
    --lumina-text-color:ivory;         /* text color for lumina  */
    --lumina-bg-color:#222121;       /* background color for lumina website  */
    --lumina-text-box-color:#272626;  /* textbox color or bg  */
    --lumina-modal-bg-color:#1B1B1B;   /* modal bg color   */
    --lumina-text-black-color:#222121;  /* text color for when background color of element is white or other color  */
    --lumina-input-icon-bg: #1f1e1e; 
    --lumina-available-icon-bg: #292a25;
    --lumina-border-line-color: #262b33;
    --lumina-grey-color: #9399a0;
    --lumina-regular:"lumina-regular";
    --lumina-black:"lumina-black"    
}

body{
    transition:linear,1000ms;
    scroll-behavior: smooth;
    
}

*{
    padding: 0;
    margin: 0;
    transition: all .3s ease-in-out;
    font-family: "lumina-regular";
    
    /* user-select: none; */
    outline:none;

    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    &::-webkit-scrollbar-track {
        background: var(--lumina-text-box-color);
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

/* default global style for button  */
button{
    border-style: solid;
    border-color:transparent;    
}

body{
    background: var(--lumina-bg-color);
}

.transparent-click{
    -webkit-tap-highlight-color: transparent;
    text-decoration: none;
}
